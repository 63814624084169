import React, { useState } from "react";
import { TextField, Button, Box, Typography, Grid, IconButton, CircularProgress, List, ListItem, ListItemText } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios'; // Make sure to install axios with `npm install axios`

// Date Format dd-mm-yyyy
const formatDate = (dateString) => {
  if (!dateString) return '';

  const [year, month, day] = dateString.split('-');

  // Pad month and day to ensure they are always two digits
  const formattedDay = day.padStart(2, '0');
  const formattedMonth = month.padStart(2, '0');

  return `${formattedDay}-${formattedMonth}-${year}`;
};

const StudentSearch = ({ onBack }) => {
  const initialFormData = {
    name: '',
    enrollmentNo: '',
    dateOfBirth: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.dateOfBirth) errors.dateOfBirth = 'Date of Birth is required';
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    setErrors(errors);

    if (Object.keys(errors).length > 0) return;

    setLoading(true);

    try {
      const response = await axios.get('https://backend.navituschemicals.in/search1.php', {
        params: formData,
      });
      setResults(response.data);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setFormData(initialFormData);
    setErrors({});
    setResults([]);
  };

  return (
    <Box p={3}>
      <Box display="flex" alignItems="center">
        <IconButton edge="start" color="inherit" onClick={onBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" align="center" style={{ fontSize: 36, flexGrow: 1 }}>
          Student Search
        </Typography>
      </Box>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Name"
              variant="outlined"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Enrollment Number"
              variant="outlined"
              name="enrollmentNo"
              value={formData.enrollmentNo}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Date of Birth"
              type="date"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              error={!!errors.dateOfBirth}
              helperText={errors.dateOfBirth}
            />
          </Grid>
        </Grid>
        <Box mt={2} display="flex" gap={2}>
          <Button type="submit" variant="contained" color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Search'}
          </Button>
          <Button type="button" variant="outlined" color="secondary" onClick={handleReset} disabled={loading}>
            Cancel
          </Button>
        </Box>
      </form>
      {results.length > 0 && (
        <Box mt={4} p={2} border={1} borderColor="grey.300" borderRadius={2} bgcolor="background.paper">
          <Typography variant="h6" mb={2} color="textPrimary">
            Search Results:
          </Typography>
          <List>
            {results.map((result, index) => (
              <ListItem key={index} divider>
                <ListItemText
                  primary={`Name: ${result.name}`}
                  secondary={
                    <>
                      <div>Enrollment Number: {result.enrollmentNo}</div>
                      <div>Date of Birth: {formatDate(result.dateOfBirth)}</div>
                      <div>Father's Name: {result.fathersName}</div>
                      <div>Course Code: {result.courseCode}</div>
                      <div>Center Code: {result.centerCode}</div>
                      <div>Session: {result.session}</div>
                    </>
                  }
                  secondaryTypographyProps={{ component: 'div' }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default StudentSearch;
